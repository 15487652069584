


.site-footer
{
    background-color:#c7c9cd;
    padding:45px 0 20px;
    font-size:16px;
    line-height:20px;
    color:#848080fd;
}
.site-footer hr
{
    border-top-color:#bbb;
    opacity:0.5
}
.site-footer hr.small
{
    margin:20px 0
}
.site-footer h6
{
    color: #696969fd;
    font-size:16px;
    text-transform:uppercase;
    margin-top:5px;
    letter-spacing:2px
}
.site-footer a
{
    color:#737373;
}
.site-footer a:hover
{
    color:#0179fa;
    text-decoration:none;
}
.footer-links
{
    padding-left:0;
    list-style:none
}
.footer-links li
{
    display:block
}
.footer-links a
{
    color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
    color:#0179fa;
    text-decoration:none;
}
.footer-links.inline li
{
    display:inline-block
}

.site-footer .social-icons a
{
    width:40px;
    height:40px;
    line-height:40px;
    margin-left:6px;
    margin-right:0;
    border-radius:100%;
    background-color:#33353d
}
.copyright-text
{
    margin:0
}
@media (max-width:991px)
{
    .site-footer [class^=col-]
    {
        margin-bottom:30px
    }
}
@media (max-width:767px)
{
    .site-footer
    {
        padding-bottom:0
    }
    .site-footer .copyright-text,.site-footer .social-icons
    {
        text-align:center
    }
}
.social-icons
{
    padding-left:0;
    margin-bottom:0;
    list-style:none
}
.social-icons li
{
    display:inline-block;
    margin-bottom:4px
}
.social-icons li.title
{
    margin-right:15px;
    text-transform:uppercase;
    color:#96a2b2;
    font-weight:700;
    font-size:13px
}
.social-icons a{
    background-color:#eceeef;
    color:#818a91;
    font-size:16px;
    display:inline-block;
    line-height:44px;
    width:44px;
    height:44px;
    text-align:center;
    margin-right:8px;
    border-radius:100%;
    -webkit-transition:all .2s linear;
    -o-transition:all .2s linear;
    transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
    color:#fff;
    background-color:#29aafe
}
.social-icons.size-sm a
{
    line-height:34px;
    height:34px;
    width:34px;
    font-size:14px
}
.social-icons a.facebook:hover
{
    background-color:#3b5998
}
.social-icons a.twitter:hover
{
    background-color:#00aced
}
.social-icons a.linkedin:hover
{
    background-color:#007bb6
}
.social-icons a.dribbble:hover
{
    background-color:#ea4c89
}
@media (max-width:767px)
{
    .social-icons li.title
    {
        display:block;
        margin-right:0;
        font-weight:600
    }
}





.footerimage{
    margin-left: 30%;
}
.footertext{
    margin-left: 20%;
}
.footercentre{
    text-align: center;
}
.footercentre2{
    text-align: center;
    /* margin-top: 2%; */
}


div[role='menu'] {
    visibility: hidden;
}

div[role='menu'].visible {
    visibility: visible;
}

#social-wrapper {
    text-align: center;
}

/*Social Media Icons*/
.social-wrapper {
    text-align: center;
}

.social-wrapper ul li {
    display: inline;
    margin: 0 5px;
}

.footer-nav p {
    text-align: center;
}

.footer-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.social-connect-icons {
    cursor: pointer;
    opacity: .6;
    height: 40px;
    width: 40px;
}

.social-connect-icons:hover {
    opacity: 1;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    transform: scale(1.3);
}

