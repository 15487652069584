.heading {
    font-family: Avenir, serif;
    font-size: 30px;
    padding: 20px 30px;
    font-weight: 500;
    color: #808080;
    text-align: left;
}

.nav-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.routing {
    padding: 20px 30px;
}

.timelock-card-container {
    padding: 20px 30px;
    background: #f5f5f5;
    height: 75vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.ant-card-head-title {
    font-family: Avenir, serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    padding:0;
}

.ant-card-bordered {
    border: 1px solid #f0f0f0;
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
    border-radius: 10px;
}

.ant-input-group .ant-input {
    border-color: #94DDF9;
}

.submitButton {
    width: 100px;
    border-radius: 10px;
    background: #2F71F0;
    font-weight: bold;
    margin-top: 20px;
}

.connectWalletButton {
    width: 200px;
    height: 40px;
    border-radius: 10px;
    background: #2F71F0;
    font-weight: bold;
    margin-bottom: 45px;
}

.alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-skeleton-element {
    padding: 10px;
    width: 100%;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.ant-skeleton-element .ant-skeleton-input {
    width: 100%;
}

.lockIcon {
    height: 80px;
    margin-left: -20px;
    margin-top: -5px;
}

.usdLockIcon {
    height: 64px;
    margin-top: 3px;
}
