.heading {
    font-family: Avenir, serif;
    font-size: 30px;
    padding: 20px 30px;
    font-weight: 500;
    color: #808080;
    text-align: left;
}

.nav-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.routing {
    padding: 20px 30px;
}

.card-container {
    padding: 20px 30px;
    background: #f5f5f5;
    height: 75vh;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
